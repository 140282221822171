// src/components/BottomNav.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const BottomNav = ({ activePage }) => {
  return (
    <nav id="bottom-nav" className="navbar fixed-bottom navbar-dark bg-dark">
      <div className="container-fluid justify-content-around">
        <Link to="/" className={`nav-link text-center text-white ${activePage === 'home' ? 'active' : ''}`}>
          <i className="bi bi-house-door-fill" style={{ fontSize: '1.5rem' }}></i>
          <div style={{ fontSize: '0.75rem' }}>Home</div>
        </Link>
        <Link to="/safety-report" className={`nav-link text-center text-white ${activePage === 'safety' ? 'active' : ''}`}>
          <i className="bi bi-exclamation-triangle-fill" style={{ fontSize: '1.5rem' }}></i>
          <div style={{ fontSize: '0.75rem' }}>Safety Report</div>
        </Link>
        <Link to="/work-updates" className={`nav-link text-center text-white ${activePage === 'updates' ? 'active' : ''}`}>
          <i className="bi bi-journal-text" style={{ fontSize: '1.5rem' }}></i>
          <div style={{ fontSize: '0.75rem' }}>Work Updates</div>
        </Link>
        <Link to="/asset-scan" className={`nav-link text-center text-white ${activePage === 'assets' ? 'active' : ''}`}>
          <i className="bi bi-upc-scan" style={{ fontSize: '1.5rem' }}></i>
          <div style={{ fontSize: '0.75rem' }}>Assets</div>
        </Link>
        {/* <Link to="/history" className={`nav-link text-center text-white ${activePage === 'history' ? 'active' : ''}`}>
          <i className="bi bi-clock-history" style={{ fontSize: '1.5rem' }}></i>
          <div style={{ fontSize: '0.75rem' }}>History</div>
        </Link> */}
      </div>
    </nav>
  );
};

export default BottomNav;
