import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { insertAssetDetails } from '../services/api';

const AssetDetailsSection = ({
  photos,
  assetDetails,
  setAssetDetails,
  loadingAssetDetails,
  handleIdentifyAsset,
  showUpdateOptions,
  setShowUpdateOptions,
  jobId, // Accept jobId as a prop
}) => {
  const [assetStatus, setAssetStatus] = useState('');

  const handleInsertAssetDetails = async () => {
    if (!assetDetails) {
      alert('No asset details found to update.');
      return;
    }
  
    if (!jobId) {
      alert('Job ID is missing. Cannot update asset details.');
      return;
    }
  
    try {
      // Flatten the `details` object, removing any unnecessary nesting
      const details = {
        category: assetDetails.category,
        asset_condition: assetDetails.asset_condition,
        description: assetDetails.description,
        manufacturer: assetDetails.manufacturer,
        model: assetDetails.model,
        metadata: assetDetails.metadata,
      };
  
      // Use jobId, name, status, and additional details for insert operation
      await insertAssetDetails(jobId, assetDetails.name, assetStatus || assetDetails.status, details);
       
      // Optionally update local state
      setAssetDetails({
        ...assetDetails,
        status: assetStatus || assetDetails.status,
      });
    } catch (error) {
      console.error('Failed to insert asset details:', error);
    }
  };
  

  return (
    <>
      {/* Identify Asset Button */}
      {photos.length > 0 && (
        <button id="identify-asset-button" className="btn btn-primary w-100 mb-4" onClick={handleIdentifyAsset} disabled={loadingAssetDetails}>
          {loadingAssetDetails ? 'Identifying...' : 'Identify Asset'}
        </button>
      )}

      {/* Asset Details */}
      {assetDetails && !loadingAssetDetails && (
        <section id="asset-details" className="mb-4">
          <h2>Asset Details</h2>
          <ul className="list-group">
            <li className="list-group-item">
              <label><strong>Name:</strong></label>
              <input
                type="text"
                className="form-control"
                value={assetDetails.name}
                onChange={(e) => setAssetDetails({ ...assetDetails, name: e.target.value })}
              />
            </li>
            <li className="list-group-item">
              <label><strong>Category:</strong></label>
              <input
                type="text"
                className="form-control"
                value={assetDetails.category}
                onChange={(e) => setAssetDetails({ ...assetDetails, category: e.target.value })}
              />
            </li>
            <li className="list-group-item">
              <label><strong>Condition:</strong></label>
              <input
                type="text"
                className="form-control"
                value={assetDetails.asset_condition}
                onChange={(e) => setAssetDetails({ ...assetDetails, asset_condition: e.target.value })}
              />
            </li>
            <li className="list-group-item">
              <label><strong>Description:</strong></label>
              <textarea
                className="form-control"
                value={assetDetails.description}
                onChange={(e) => setAssetDetails({ ...assetDetails, description: e.target.value })}
              />
            </li>
            <li className="list-group-item">
              <label><strong>Manufacturer:</strong></label>
              <input
                type="text"
                className="form-control"
                value={assetDetails.manufacturer}
                onChange={(e) => setAssetDetails({ ...assetDetails, manufacturer: e.target.value })}
              />
            </li>
            <li className="list-group-item">
              <label><strong>Model:</strong></label>
              <input
                type="text"
                className="form-control"
                value={assetDetails.model}
                onChange={(e) => setAssetDetails({ ...assetDetails, model: e.target.value })}
              />
            </li>
          </ul>
        </section>
      )}

      {/* Update Status */}
      {showUpdateOptions && (
        <section id="update-status" className="mb-4">
          <h2>Update Asset Status</h2>
          <div className="btn-group w-100" role="group" aria-label="Asset Status">
            <input type="radio" className="btn-check" name="assetStatus" id="statusInstalled" value="Installed" onChange={(e) => setAssetStatus(e.target.value)} autoComplete="off" />
            <label className="btn btn-outline-primary" htmlFor="statusInstalled">Installed</label>

            <input type="radio" className="btn-check" name="assetStatus" id="statusServiced" value="Serviced" onChange={(e) => setAssetStatus(e.target.value)} autoComplete="off" />
            <label className="btn btn-outline-primary" htmlFor="statusServiced">Serviced</label>

            <input type="radio" className="btn-check" name="assetStatus" id="statusNeedsRepair" value="Needs Repair" onChange={(e) => setAssetStatus(e.target.value)} autoComplete="off" />
            <label className="btn btn-outline-primary" htmlFor="statusNeedsRepair">Needs Repair</label>
          </div>
        </section>
      )}

      {/* Update Button */}
      {showUpdateOptions && (
        <button id="update-asset-button" className="btn btn-success w-100" onClick={handleInsertAssetDetails}>
          Update Asset
        </button>
      )}
    </>
  );
};

AssetDetailsSection.propTypes = {
  photos: PropTypes.array.isRequired,
  assetDetails: PropTypes.object,
  setAssetDetails: PropTypes.func.isRequired,
  loadingAssetDetails: PropTypes.bool.isRequired,
  handleIdentifyAsset: PropTypes.func.isRequired,
  showUpdateOptions: PropTypes.bool.isRequired,
  setShowUpdateOptions: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired, // Add PropTypes validation for jobId
};

export default AssetDetailsSection;
