import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../assets/styles.css';
import Header from '../components/Header';
import BottomNav from '../components/BottomNav';
import VoiceNoteRecorder from '../components/VoiceNoteRecorder';
import { addImage, getJobDetails, getVoiceNotes } from '../services/api';
import ImageGallery from '../components/ImageGallery';
import SummarySection from '../components/SummarySection';
import SignOffCheckbox from '../components/SignOffCheckbox';
import ActionButtons from '../components/ActionButtons';

const WorkUpdatesPage = () => {
  const [summaryLength, setSummaryLength] = useState('3');
  const [summaryText, setSummaryText] = useState('');
  const [isSignedOff, setIsSignedOff] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [voiceNotes, setVoiceNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const jobDetailsFromSession = sessionStorage.getItem('jobDetails');
    if (jobDetailsFromSession) {
      const { job_id } = JSON.parse(jobDetailsFromSession);
      fetchJobDetails(job_id);
    } else {
      setError('No job details found in session. Please go back to the home page.');
      setLoading(false);
    }
  }, []);

  const fetchJobDetails = async (jobId) => {
    try {
      const details = await getJobDetails(jobId);
      setJobDetails(details.body.record);
      setSummaryText(details.body.record.summary || '');
      setIsSignedOff(details.body.record.is_reviewed_accurate === 1);
      fetchVoiceNotes(jobId, 'report');
    } catch (error) {
      console.error('Failed to fetch job details:', error);
      setError('Failed to load job details. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchVoiceNotes = async (jobId, type) => {
    try {
      const notes = await getVoiceNotes(jobId, type);
      setVoiceNotes(notes);
    } catch (error) {
      console.error('Error fetching voice notes:', error);
    }
  };

  if (loading) {
    return (
      <div className="text-center my-5">
        <i className="bi bi-arrow-repeat" style={{ fontSize: '3rem' }}></i>
        <h2 className="mt-3">Loading...</h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center my-5">
        <i className="bi bi-exclamation-triangle-fill text-danger" style={{ fontSize: '3rem' }}></i>
        <h2 className="mt-3">Oops! {error}</h2>
        <p className="lead">Please check the link or contact your supervisor for assistance.</p>
      </div>
    );
  }

  return (
    <div style={{ paddingTop: '0px' }}>
      <Header title="Job Report" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000, backgroundColor: '#007bff', color: '#fff' }} />

      <main className="container-fluid my-4 px-3" style={{ paddingBottom: '120px', overflowY: 'auto', height: 'calc(100vh - 160px)' }}>
        {jobDetails && (
          <>
            {/* Image Gallery Section */}
            <ImageGallery
              jobId={jobDetails?.job_id}
              addImage={addImage}
              type="job"
              photos={photos}
              setPhotos={setPhotos}
              fetchOnLoad={true}
            />
            
            {/* Summary Section */}
            <SummarySection
              jobId={jobDetails?.job_id}
              summaryLength={summaryLength}
              setSummaryLength={setSummaryLength}
              summaryText={summaryText}
              setSummaryText={setSummaryText}
            />
            
            {/* Voice Note Recorder Section */}
            <VoiceNoteRecorder
              jobId={jobDetails?.job_id}
              type="report"
              voiceNotes={voiceNotes}
            />
            
            {/* Sign Off Checkbox Section */}
            <SignOffCheckbox isSignedOff={isSignedOff} setIsSignedOff={setIsSignedOff} />
            
            {/* Action Buttons */}
            <ActionButtons
              jobDetails={jobDetails}
              summaryText={summaryText}
              isSignedOff={isSignedOff}
            />
          </>
        )}
      </main>

      <BottomNav activePage="work-updates" style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 1000 }} />
    </div>
  );
};

export default WorkUpdatesPage;
