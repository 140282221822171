import React from 'react';
import PropTypes from 'prop-types';

const SafetyDescription = ({ description, setDescription }) => {
  return (
    <section id="description-field" className="mb-4">
      <h2>Description</h2>
      <textarea
        id="description"
        rows="5"
        className="form-control"
        placeholder="Describe the safety issue..."
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </section>
  );
};

SafetyDescription.propTypes = {
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
};

export default SafetyDescription;
