// ActionButtons component
import React from 'react';
import PropTypes from 'prop-types';
import { updateJobDetails } from '../services/api';

const ActionButtons = ({ jobDetails, summaryText, isSignedOff }) => {
  const handleSaveDraft = async () => {
    if (!jobDetails) {
      return;
    }
    const updatedJobData = {
      job_id: jobDetails.job_id,
      summary: summaryText,
      is_reviewed_accurate: isSignedOff ? 1 : 0, // Corrected to send numeric value
      status: jobDetails.status
    };

    try {
      await updateJobDetails(updatedJobData);
    } catch (error) {
      console.error('Failed to save draft:', error);
    }
  };

  const handleSubmitReport = async () => {
    if (!jobDetails) {
      return;
    }
    if (isSignedOff) {
      const updatedJobData = {
        job_id: jobDetails.job_id,
        summary: summaryText,
        is_reviewed_accurate: isSignedOff ? 1 : 0, // Corrected to send numeric value
        status: 'submitted',
      };

      try {
        await updateJobDetails(updatedJobData);
        window.location.href = '/'; // Navigate back to the home page
      } catch (error) {
        console.error('Failed to submit report:', error);
      }
    } else {
      alert('Please confirm the report is accurate by checking the sign-off box.');
    }
  };

  return (
    <div id="action-buttons" className="d-flex gap-3">
      <button id="save-draft-button" className="btn btn-secondary flex-fill" onClick={handleSaveDraft}>Save Draft</button>
      <button id="submit-report-button" className="btn btn-success flex-fill" onClick={handleSubmitReport}>Submit Report</button>
    </div>
  );
};

ActionButtons.propTypes = {
  jobDetails: PropTypes.object,
  summaryText: PropTypes.string,
  isSignedOff: PropTypes.bool
};

export default ActionButtons;
