import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getImages, deleteImage } from '../services/api';

const ImageGallery = ({ jobId, addImage, type, fetchOnLoad = true, photos, setPhotos }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (fetchOnLoad && jobId && type) {
      fetchJobImages(jobId, type);
    }
  }, [jobId, type, fetchOnLoad]);

  const fetchJobImages = async (jobId, type) => {
    try {
      const images = await getImages(jobId, type);
      setPhotos(images.map(image => ({
        image_data: image.image_data,
        image_id: image.image_id,
      })));
    } catch (error) {
      console.error('Failed to fetch job images:', error);
      setError('Failed to load job images. Please try again later.');
    }
  };

  const handlePhotoCapture = async () => {
    if (photos.length >= 6) {
      alert('Maximum of 6 images allowed.');
      return;
    }
  
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' } // Use the back camera (environment)
      });
      const video = document.createElement('video');
      video.setAttribute('playsinline', 'true');
      video.style.width = '100%';
      video.style.maxWidth = '200px';
      video.style.height = 'auto';
      video.srcObject = stream;
      await video.play();
  
      // Calculate the aspect ratio based on video dimensions
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;
      const aspectRatio = videoWidth / videoHeight;
  
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
  
      const captureArea = document.getElementById('capture-area');
      captureArea.innerHTML = '';
      captureArea.appendChild(video);
  
      const captureButton = document.createElement('button');
      captureButton.innerText = 'Capture Photo';
      captureButton.className = 'btn btn-primary w-100 w-md-auto mt-3';
      captureArea.appendChild(captureButton);
  
      captureButton.addEventListener('click', async () => {
        // Set canvas dimensions based on the aspect ratio, e.g., width 640 and corresponding height
        const captureWidth = 640;
        const captureHeight = captureWidth / aspectRatio;
        canvas.width = captureWidth;
        canvas.height = captureHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        
        // Use JPEG format and compression to keep file size below 1 MB
        let dataUrl = canvas.toDataURL('image/jpeg', 0.7); // Initial compression level at 0.7
  
        // Stop the video stream to free resources
        stream.getTracks().forEach(track => track.stop());
        captureArea.innerHTML = '';
  
        // Check file size and retry with lower quality if needed
        let quality = 0.7;
        while (dataUrl.length > 1_000_000 && quality > 0.5) {
          quality -= 0.1;
          dataUrl = canvas.toDataURL('image/jpeg', quality);
        }
  
        try {
          const response = await addImage({ job_id: jobId, type: type, image_data: dataUrl.split(',')[1] });
  
          if (response.body && response.body.record && response.body.record.image_id) {
            setPhotos((prevPhotos) => [
              ...prevPhotos,
              {
                image_data: dataUrl,
                image_id: response.body.record.image_id,
              },
            ]);
          }
        } catch (error) {
          console.error('Failed to save image:', error);
        }
      });
    } catch (error) {
      console.error('Error accessing camera:', error);
      alert('Could not access camera. Please ensure your device has a camera and permissions are granted.');
    }
  };

  const handlePhotoDelete = async (imageId, index) => {
    if (!imageId) {
      console.error('Image ID is missing for deletion');
      return;
    }

    try {
      await deleteImage(imageId);
      setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
    } catch (error) {
      console.error('Failed to delete image:', error);
    }
  };

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <section id="image-gallery" className="mb-4">
      <h2>{`${type.charAt(0).toUpperCase() + type.slice(1)} Images`}</h2>
      <div id="images" className="row g-3">
        {photos.map((photo, index) => (
          <div className="col-4" key={index}>
            <img
              src={photo.image_data}
              alt={`${type.charAt(0).toUpperCase() + type.slice(1)} Image ${index + 1}`}
              className="img-fluid border"
            />
            <button
              className="btn btn-danger btn-sm mt-2 w-100"
              onClick={() => handlePhotoDelete(photo.image_id, index)}
            >
              Delete Image
            </button>
          </div>
        ))}
        {photos.length < 6 && (
          <div className="col-4">
            <button
              id="add-image-button"
              className="btn btn-primary w-100 h-100 d-flex flex-column align-items-center justify-content-center"
              onClick={handlePhotoCapture}
            >
              <i className="bi bi-plus-circle-fill mb-2" style={{ fontSize: '2rem' }}></i>
              <span>Add Image</span>
            </button>
          </div>
        )}
      </div>
      <div id="capture-area" className="mt-3"></div>
    </section>
  );
};

ImageGallery.propTypes = {
  jobId: PropTypes.number,
  addImage: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  fetchOnLoad: PropTypes.bool,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      image_data: PropTypes.string.isRequired,
      image_id: PropTypes.number.isRequired,
    })
  ).isRequired,
  setPhotos: PropTypes.func.isRequired,
};

export default ImageGallery;
